import { useState, useEffect } from 'react';
import MDEditor from '@uiw/react-md-editor';
import { useNavigate } from 'react-router-dom';
import '@uiw/react-md-editor/markdown-editor.css';
import '@uiw/react-markdown-preview/markdown.css';

interface EditorProps {
  path: string;
}

interface Metadata {
  title: string;
  description: string;
  date: string;
  video: {
    src: string;
    poster: string;
  };
}

const OWNER = 'IonGlue';
const REPO = 'the-fellows-nl-v2';
const API_BASE_URL = 'https://api.github.com';

const parseYamlFrontmatter = (frontmatter: string): Metadata => {
  const metadata: Metadata = {
    title: '',
    description: '',
    date: new Date().toISOString().split('T')[0],
    video: {
      src: '',
      poster: ''
    }
  };

  const lines = frontmatter.split('\n');
  let isInVideo = false;

  lines.forEach(line => {
    const trimmedLine = line.trim();
    if (trimmedLine.startsWith('video:')) {
      isInVideo = true;
    } else if (isInVideo && trimmedLine.startsWith('src:')) {
      const value = trimmedLine.substring(4).trim();
      metadata.video.src = value.replace(/["']/g, '');
    } else if (isInVideo && trimmedLine.startsWith('poster:')) {
      const value = trimmedLine.substring(7).trim();
      metadata.video.poster = value.replace(/["']/g, '');
    } else if (trimmedLine.includes(':') && !trimmedLine.startsWith(' ')) {
      isInVideo = false;
      const [key, value] = trimmedLine.split(':').map(s => s.trim());
      if (value) {
        (metadata as any)[key] = value.replace(/["']/g, '');
      }
    }
  });

  return metadata;
};

const generateYamlFrontmatter = (metadata: Metadata): string => {
  const videoId = extractVideoId(metadata.video.src);
  return `title: "${metadata.title}"
description: "${metadata.description}"
date: ${metadata.date}
video:
  src: "https://customer-kj5kk7ynq9jpj38f.cloudflarestream.com/${videoId}/iframe?poster=https%3A%2F%2Fcustomer-kj5kk7ynq9jpj38f.cloudflarestream.com%2F${videoId}%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
  poster: "https://customer-kj5kk7ynq9jpj38f.cloudflarestream.com/${videoId}/thumbnails/thumbnail.jpg?time=&height=600"`;
};

const extractVideoId = (url: string): string => {
  if (!url) return '';
  
  if (/^[a-f0-9]{32}$/.test(url)) {
    return url;
  }

  const match = url.match(/cloudflarestream\.com\/([a-f0-9]{32})/);
  return match ? match[1] : '';
};

const extractFrontmatterAndContent = (rawContent: string) => {
  const lines = rawContent.split('\n');
  let frontmatter = '';
  let content = '';
  let inFrontmatter = false;
  let frontmatterCount = 0;

  for (const line of lines) {
    if (line.trim() === '---') {
      frontmatterCount++;
      if (frontmatterCount === 1) {
        inFrontmatter = true;
        continue;
      }
      if (frontmatterCount === 2) {
        inFrontmatter = false;
        continue;
      }
    }

    if (inFrontmatter) {
      frontmatter += line + '\n';
    } else if (frontmatterCount >= 2) {
      content += line + '\n';
    } else {
      content += line + '\n';
    }
  }

  return {
    frontmatter: frontmatter.trim(),
    content: content.trim()
  };
};

const combineFrontmatterAndContent = (metadata: Metadata, content: string) => {
  const frontmatter = generateYamlFrontmatter(metadata);
  return `---\n${frontmatter}\n---\n\n${content}`;
};

export default function MarkdownEditor({ path }: EditorProps) {
  const [originalContent, setOriginalContent] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [originalMetadata, setOriginalMetadata] = useState<Metadata>({
    title: '',
    description: '',
    date: new Date().toISOString().split('T')[0],
    video: {
      src: '',
      poster: ''
    }
  });
  const [metadata, setMetadata] = useState<Metadata>({
    title: '',
    description: '',
    date: new Date().toISOString().split('T')[0],
    video: {
      src: '',
      poster: ''
    }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sha, setSha] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pendingDelete, setPendingDelete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/repos/${OWNER}/${REPO}/contents/${path}`, {
          headers: {
            'Accept': 'application/vnd.github.v3+json'
          }
        });

        if (!response.ok) {
          if (response.status === 404) {
            setLoading(false);
            return;
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if ('content' in data && 'sha' in data) {
          const decodedContent = atob(data.content);
          const { frontmatter, content } = extractFrontmatterAndContent(decodedContent);
          const parsedMetadata = parseYamlFrontmatter(frontmatter);
          
          setOriginalContent(content);
          setContent(content);
          setOriginalMetadata(parsedMetadata);
          setMetadata(parsedMetadata);
          setSha(data.sha);
        }
        setLoading(false);
      } catch (err) {
        const error = err as Error;
        setError(error.message || 'Failed to fetch file content');
        setLoading(false);
      }
    };

    fetchContent();
  }, [path]);

  const hasChanges = () => {
    return (
      content !== originalContent ||
      JSON.stringify(metadata) !== JSON.stringify(originalMetadata) ||
      pendingDelete
    );
  };

  const handleSubmit = async () => {
    if (!hasChanges()) return;

    try {
      setIsSubmitting(true);

      if (pendingDelete) {
        const response = await fetch(`${API_BASE_URL}/repos/${OWNER}/${REPO}/contents/${path}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/vnd.github.v3+json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            message: `Delete ${path}`,
            sha: sha,
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to delete file: ${response.statusText}`);
        }

        navigate('/browse');
        return;
      }

      const combinedContent = combineFrontmatterAndContent(metadata, content);
      const contentEncoded = btoa(combinedContent);

      const response = await fetch(`${API_BASE_URL}/repos/${OWNER}/${REPO}/contents/${path}`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/vnd.github.v3+json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message: `Update ${path}`,
          content: contentEncoded,
          sha: sha || undefined,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setSha(data.content.sha);
      setOriginalContent(content);
      setOriginalMetadata(metadata);
      setPendingDelete(false);
      navigate('/browse');
    } catch (err) {
      const error = err as Error;
      setError(error.message || 'Failed to save changes');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleMetadataChange = (field: keyof Metadata, value: string) => {
    if (field === 'video') {
      const newVideoId = value.trim();
      setMetadata(prev => ({
        ...prev,
        video: {
          src: `https://customer-kj5kk7ynq9jpj38f.cloudflarestream.com/${newVideoId}/iframe?poster=https%3A%2F%2Fcustomer-kj5kk7ynq9jpj38f.cloudflarestream.com%2F${newVideoId}%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600`,
          poster: `https://customer-kj5kk7ynq9jpj38f.cloudflarestream.com/${newVideoId}/thumbnails/thumbnail.jpg?time=&height=600`
        }
      }));
    } else {
      setMetadata(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  const handleMarkForDeletion = () => {
    setPendingDelete(true);
    setShowDeleteModal(false);
  };

  const handleUndoDelete = () => {
    setPendingDelete(false);
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  const videoId = extractVideoId(metadata.video.src);

  return (
    <div className={`container ${pendingDelete ? 'pending-delete' : ''}`} data-color-mode="dark">
      <div className="header">
        <div className="header-left">
          <button onClick={() => navigate('/browse')} className="nav-button back-button">
            ← Back
          </button>
          <h2>Editing: {path}</h2>
        </div>
        <div className="header-right">
          {pendingDelete ? (
            <button 
              onClick={handleUndoDelete}
              className="nav-button undo-button"
              disabled={isSubmitting}
            >
              Undo Delete
            </button>
          ) : (
            <button 
              onClick={() => setShowDeleteModal(true)} 
              className="nav-button delete-button"
              disabled={isSubmitting}
            >
              <svg className="trash-icon" viewBox="0 0 24 24" width="16" height="16">
                <path fill="currentColor" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
              </svg>
              Delete
            </button>
          )}
          {hasChanges() && (
            <button 
              onClick={handleSubmit} 
              className="nav-button submit-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Changes'}
            </button>
          )}
        </div>
      </div>
      <div className="editor-container">
        <div className="metadata-editor">
          <h3>Metadata</h3>
          <div className="metadata-fields">
            <div className="field">
              <label>Title</label>
              <input
                type="text"
                value={metadata.title}
                onChange={(e) => handleMetadataChange('title', e.target.value)}
                placeholder="Enter title..."
                disabled={pendingDelete}
              />
            </div>
            <div className="field">
              <label>Description</label>
              <textarea
                value={metadata.description}
                onChange={(e) => handleMetadataChange('description', e.target.value)}
                placeholder="Enter description..."
                disabled={pendingDelete}
              />
            </div>
            <div className="field">
              <label>Date</label>
              <input
                type="date"
                value={metadata.date}
                onChange={(e) => handleMetadataChange('date', e.target.value)}
                disabled={pendingDelete}
              />
            </div>
            <div className="field">
              <label>Video ID</label>
              <input
                type="text"
                value={videoId}
                onChange={(e) => handleMetadataChange('video', e.target.value)}
                placeholder="Enter video ID..."
                disabled={pendingDelete}
              />
            </div>
          </div>
        </div>
        <div className="content-editor">
          <h3>Content</h3>
          <MDEditor
            value={content}
            onChange={(value) => setContent(value || '')}
            height={600}
            preview="live"
            hideToolbar={false}
            enableScroll={true}
            textareaProps={{
              disabled: pendingDelete
            }}
          />
        </div>
      </div>

      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Delete Case Study</h2>
            <p>Are you sure you want to mark this case study for deletion? You can undo this action before submitting changes.</p>
            <div className="modal-buttons">
              <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
              <button 
                onClick={handleMarkForDeletion}
                className="delete-button"
              >
                Mark for Deletion
              </button>
            </div>
          </div>
        </div>
      )}

      <style>{`
        .container {
          padding: 20px;
          background: #1e1e1e;
          min-height: 100vh;
          transition: all 0.3s ease;
        }

        .container.pending-delete {
          background: #1e1e1e88;
          position: relative;
        }

        .container.pending-delete::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 4px solid #d73a49;
          pointer-events: none;
          border-radius: 8px;
        }

        .loading,
        .error {
          padding: 20px;
          text-align: center;
          color: #d4d4d4;
        }

        .error {
          background: rgba(255, 107, 107, 0.1);
          color: #ff6b6b;
          border-radius: 4px;
        }

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          padding: 12px 16px;
          background: #2d2d2d;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }

        .header-left {
          display: flex;
          align-items: center;
          gap: 16px;
        }

        .header-right {
          display: flex;
          gap: 12px;
        }

        .header h2 {
          margin: 0;
          color: #d4d4d4;
          font-size: 1.2rem;
        }

        .nav-button {
          padding: 8px 16px;
          border: none;
          border-radius: 6px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.2s ease;
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          gap: 6px;
        }

        .nav-button:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        .back-button {
          background: #3d3d3d;
          color: #d4d4d4;
        }

        .back-button:hover:not(:disabled) {
          background: #4d4d4d;
          transform: translateX(-2px);
        }

        .submit-button {
          background: #2ea043;
          color: white;
        }

        .submit-button:hover:not(:disabled) {
          background: #3fb950;
          transform: translateY(-1px);
        }

        .delete-button {
          background: #d73a49;
          color: white;
        }

        .delete-button:hover:not(:disabled) {
          background: #cb2431;
        }

        .undo-button {
          background: #0d6efd;
          color: white;
        }

        .undo-button:hover:not(:disabled) {
          background: #0b5ed7;
        }

        .trash-icon {
          stroke: currentColor;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
          fill: none;
        }

        .nav-button:active:not(:disabled) {
          transform: translateY(1px);
        }

        h3 {
          color: #d4d4d4;
          margin-bottom: 10px;
        }

        .editor-container {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .metadata-editor {
          background: #2d2d2d;
          padding: 20px;
          border-radius: 8px;
          margin-bottom: 20px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .metadata-fields {
          display: grid;
          gap: 15px;
        }

        .field {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        .field label {
          color: #d4d4d4;
          font-weight: 500;
          font-size: 0.9rem;
        }

        .field input,
        .field textarea {
          padding: 8px 12px;
          background: #1e1e1e;
          border: 1px solid #3d3d3d;
          border-radius: 6px;
          color: #e0e0e0;
          font-family: inherit;
          transition: border-color 0.2s ease;
        }

        .field input:disabled,
        .field textarea:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        .field input:focus,
        .field textarea:focus {
          border-color: #0d6efd;
          outline: none;
        }

        .field textarea {
          min-height: 80px;
          resize: vertical;
        }

        .content-editor {
          flex-grow: 1;
        }

        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.75);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal {
          background: #2d2d2d;
          padding: 24px;
          border-radius: 8px;
          width: 100%;
          max-width: 400px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .modal h2 {
          color: #e0e0e0;
          margin: 0 0 16px 0;
        }

        .modal p {
          color: #d4d4d4;
          margin: 0 0 24px 0;
        }

        .modal-buttons {
          display: flex;
          justify-content: flex-end;
          gap: 12px;
        }

        .modal-buttons button {
          padding: 8px 16px;
          border: none;
          border-radius: 6px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.2s ease;
        }

        .modal-buttons button:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        .modal-buttons button:first-child {
          background: #3d3d3d;
          color: #d4d4d4;
        }

        .modal-buttons button:first-child:hover:not(:disabled) {
          background: #4d4d4d;
        }

        .w-md-editor {
          background-color: #1e1e1e !important;
          border-radius: 8px;
          overflow: hidden;
        }

        .w-md-editor-text-pre > code,
        .w-md-editor-text-input {
          color: #e0e0e0 !important;
          -webkit-text-fill-color: #e0e0e0 !important;
          background-color: #1e1e1e !important;
        }

        .wmde-markdown-color {
          background-color: #1e1e1e !important;
          color: #e0e0e0 !important;
        }

        .w-md-editor-text {
          color: #e0e0e0 !important;
        }

        .w-md-editor-preview {
          box-shadow: inset 1px 0 0 0 #2d2d2d !important;
          background-color: #1e1e1e !important;
          color: #e0e0e0 !important;
        }

        .w-md-editor-preview h1,
        .w-md-editor-preview h2,
        .w-md-editor-preview h3,
        .w-md-editor-preview h4,
        .w-md-editor-preview h5,
        .w-md-editor-preview h6,
        .w-md-editor-preview p,
        .w-md-editor-preview li {
          color: #e0e0e0 !important;
        }
      `}</style>
    </div>
  );
}
